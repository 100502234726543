import React from 'react';
import AssetGalleryDialogState from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import cloneDeep from 'helpers/cloneDeep';
import AssetAudioTrimmerView from './components/asset-audio-trimmer-view';
import { Audio } from './components/asset-audio-trimmer-view';

interface AssetAudioTrimmerWrapperState {
    value: AssetGalleryDialogState['value'];
    canSkipTrimmer: AssetGalleryDialogState['conditionProps']['canSkipTrimmer'];
    duration: AssetGalleryDialogState['data']['assetData']['duration'];
    minimumDuration: AssetGalleryDialogState['data']['assetData']['minimumDuration'];
    maximumDuration: AssetGalleryDialogState['data']['assetData']['maximumDuration'];
}

interface Props {
    onClose: () => void; // Closes the dialog.
    onMutation: (data: Audio | undefined, dataType?: string) => void; // Saves data to the store.
    onBack: () => void;
}

/**
 * This component is responsible injecting props from the component store to the AssetAudioTrimmerView component and rendering AssetAudioTrimmerView.
 * @param onClose Closes the dialog.
 * @param onMutation Saves data to the store.
 * @param onBack Go back to the previous view.
 */
const AssetAudioTrimmerWrapper: React.FC<Props> = ({ onClose, onMutation, onBack }) => {
    const { value, canSkipTrimmer, duration, minimumDuration, maximumDuration } = useComponentStore<AssetAudioTrimmerWrapperState>('AssetGalleryDialog', {
        fields: {
            value: 'value',
            canSkipTrimmer: 'conditionProps.canSkipTrimmer',
            duration: 'data.assetData.duration',
            minimumDuration: 'data.assetData.minimumDuration',
            maximumDuration: 'data.assetData.maximumDuration'
        }
    });

    /**
     * Save provided audio if skipTrim is false, otherwise save original audio.
     * @param selectedAudio Trimmed audio.
     * @param skipTrim Whether the trimming has been skipped.
     */
    const handleTrimmedAudio = (selectedAudio?: Audio, skipTrim?: boolean) => {
        if (skipTrim) {
            const valueCopy = cloneDeep(value);
            valueCopy['isTrimmed'] = true; // Set isTrimmed to true so the AssetAudioTrimmerView can open on launch.

            // Save original audio and close dialog
            onMutation(valueCopy);
            onClose();
            return;
        }

        ComponentStoreHelpers.setModel('AssetGalleryDialog', 'value', selectedAudio);

        // Save trimmed audio and close dialog
        onMutation(selectedAudio);
        onClose();
    };

    return (
        <AssetAudioTrimmerView
            audio={value}
            goBack={onBack}
            onSkipTrimmer={() => handleTrimmedAudio(undefined, true)}
            canSkipTrimmer={canSkipTrimmer}
            duration={duration}
            minimumDuration={minimumDuration}
            maximumDuration={maximumDuration}
            onMutation={(value) => handleTrimmedAudio(value)}
        />
    );
};

export default AssetAudioTrimmerWrapper;
